<template>
  <div
    class="text-center"
  >
    <div v-if="nota !== null"
      class="d-block justify-content-between align-items-center"
    >
      <div class="text-center ml-50 mr-50">
        {{ nivelLogro }}
      </div>
      <div class="pl-50 pr-50 mr-0 text-center rounded" :style="`background: ${color}; color: ${textColor};`">
        {{ nota }}
      </div>
    </div>
    <div
      v-else-if="nota === null"
    >
      -
    </div>

  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { formatos } from '@core/mixins/ui/formatos'

export default {
  components: {
  },
  data() {
    return {
      nota: null,
      nivelLogro: null,
      color: null,
      textColor: null,
    }
  },
  mixins: [formatos],
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    if (this.item.promedioAsignaturas.length > 0) {
      this.setNotas(this.item.promedioAsignaturas, this.field)
    }
  },
  methods: {
    setNotas(notas, field) {
      const notaAsignatura = notas.find(n => n.idAsignatura === Number(field.key))
      this.nota = notaAsignatura.nota
      this.nivelLogro = notaAsignatura.nivel
      this.setColor(this.nota)
    },
    setColor(nota) {
      this.color = null
      this.textColor = null
      const { color, textColor } = this.formatoColoresNotas(nota)
      this.color = color
      this.textColor = textColor
    },
  },
}
</script>
