<template>
  <b-row>
    <!-- Field: Curso -->
    <b-col
      cols="12"
      md="12"
    >
      <b-form-group
        label="Cursos"
        label-for="curso"
      >
        <b-overlay
          :show="configs.cargandoCursos"
          spinner-variant="primary"
          variant="semi-dark"
        >
          <v-select
            v-model="cursoSelected"
            placeholder="Selecciona un curso"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="cursosOptions"
            label="nombre"
            :reduce="option => option.id"
            @input="changeCurso"
          />
          <template #no-options>
            Ups! no tienes cursos asignados.
          </template>
        </b-overlay>
      </b-form-group>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BRow, BCol, BFormGroup, BOverlay,
    vSelect,
  },
  computed: {
    ...mapGetters({
      getPeriodoHistorico: 'periodos/getPeriodoHistorico',
    }),
    alertSinAsignaturas() {
      return this.tipoEnseñanzas.length
    },
  },
  data() {
    return {
      configs: {
        cargandoCursos: true,
      },
      cursoSelected: null,
      cursosOptions: [],
    }
  },
  watch: {
    getPeriodoHistorico() {
      this.cursoSelected = null
      this.loadCursos()
      this.changeCurso()
    },
  },
  mounted() {
    this.loadCursos()
  },
  methods: {
    ...mapActions({
      fetchCursosUsuario: 'cursos/fetchCursosUsuario',
      fetchAsignaturasUsuario: 'asignaturas/fetchAsignaturasUsuario',
    }),
    async loadCursos() {
      this.configs.cargandoCursos = true
      const response = await this.fetchCursosUsuario()
      await this.setCursos(response)
      this.configs.cargandoCursos = false
    },
    setCursos(cursos) {
      this.cursosOptions = []
      cursos.forEach(curso => {
        const nombre = `${curso.nombreGrado} ${curso.letra}`
        this.cursosOptions.push({
          id: curso.id,
          nombre,
          idNivel: curso.idNivel,
        })
      })
    },
    changeCurso() {
      this.$emit('showAlumnosObjetivos', this.cursoSelected)
    },
  },
}
</script>

<style>

</style>
