<template>
  <div
    style=""
    class="pl-50 pr-50"
  >
    {{ nivelLogro }}
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  components: {
  },
  data() {
    return {
      nivelLogro: null,
      variant: 'secondary',
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.setConversionNota(this.item.nivelFinal)
  },
  methods: {
    setConversionNota(nota) {
      if (nota.length) {
        this.nivelLogro = nota
      } else {
        this.nivelLogro = '-'
      }
    },
  },
}
</script>
