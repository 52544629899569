<template>
  <div
    class="rounded pt-25"
    :style="`background: ${color}; color: ${textColor}; height:26px !important;`"
  >
    <spinner
      v-if="showSpinner"
      size="small"
    />
    {{ promedio === 'NaN' ? '-' : promedio }}
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { formatos } from '@core/mixins/ui/formatos'
import spinner from '../../../components/spinner.vue'

export default {
  components: {
    spinner,
  },
  mixins: [formatos],
  data() {
    return {
      promedio: null,
      color: null,
      textColor: null,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showSpinner() {
      return false
    },
  },
  mounted() {
    this.setConversionNota(this.item.promedioFinal)
    // Obtener los datos de la tabla conversion notas y mostrar la nota correspondiente
    // al promedio de los puntajes (cantidad indicadores y puntaje obtenido)
  },
  methods: {
    setConversionNota(nota) {
      if (nota.length) {
        this.promedio = nota
        this.setColor(this.promedio)
      } else {
        this.promedio = '-'
        this.setColor(null)
      }
    },
    setColor(nota) {
      this.color = null
      this.textColor = null
      const { color, textColor } = this.formatoColoresNotas(nota)
      this.color = color
      this.textColor = textColor
    },
  },
}
</script>
