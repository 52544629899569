<template>
  <div>
    <b-card
      class="mb-1"
    >
      <resumenFiltro
        @showAlumnosObjetivos="showAlumnosObjetivos"
      />
    </b-card>

    <b-overlay
      :show="configs.cargandoData"
      spinner-variant="primary"
      :class="configs.cargandoData ? 'mt-5' : ''"
      variant="semi-dark"
    >
      <resumenAlumnosObjetivos
        v-if="mostrarAlumnosObjetivos"
        :alumnos="alumnos"
        :notasAlumnos.sync="notasAlumnos"
        :idCursoSelected.sync="idCursoSelected"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BOverlay, BAlert,
} from 'bootstrap-vue'
// import alumnosList from './components/AlumnosList.vue'
import { mapActions, mapGetters } from 'vuex'

import resumenFiltro from './components/ResumenFiltro.vue'
import resumenAlumnosObjetivos from './components/ResumenAlumnosObjetivos.vue'

export default {
  components: {
    BCard,
    BOverlay,
    BAlert,
    // alumnosList,
    resumenFiltro,
    resumenAlumnosObjetivos,
  },
  data() {
    return {
      configs: {
        cargandoData: false,
      },
      cargandoOverlay: true,
      // Globals
      idUsuario: null,
      idEstablecimientoActivo: null,
      idPeriodoActivo: null,
      idCursoSelected: null,

      // Filtros
      tipoEnseñanzas: [],
      cursos: [],
      asignaturas: [],

      // AlumnosIndicadores
      mostrarAlumnosObjetivos: false,
      alumnos: [],
      notasAlumnos: [],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getAlumnosCurso: 'alumnos/getAlumnosCurso',
      getNotas: 'notas/getNotas',
    }),
    alertSinAsignaturas() {
      return this.tipoEnseñanzas.length
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    ...mapActions({
      fetchAlumnosCurso: 'alumnos/fetchAlumnosCurso',
      fetchAllNotasCurso: 'notas/fetchAllNotasCurso',
    }),
    initialize() {
      this.idPeriodoActivo = this.getUser.idPeriodoActivo === null
                              ? 3
                              : this.getUser.idPeriodoActivo
    },
    async showAlumnosObjetivos(cursoSelected) {
      this.configs.cargandoData = true
      this.mostrarAlumnosObjetivos = false
      this.alumnos = []
      this.notasAlumnos = []
      this.idCursoSelected = cursoSelected
      if (cursoSelected !== null) {
        await this.fetchsAlumnosObjetivos(cursoSelected)
        this.mostrarAlumnosObjetivos = true
      }
      this.configs.cargandoData = false
    },
    async fetchsAlumnosObjetivos(cursoSelected) {
      const params = {
        idPeriodo: this.idPeriodoActivo,
        idCurso: this.idCursoSelected,
      }
      await Promise.all([
        this.fetchAlumnosCurso(cursoSelected),
        this.fetchAllNotasCurso(params)
      ])
      this.alumnos = this.getAlumnosCurso
      this.notasAlumnos = this.getNotas
      this.mostrarAlumnosObjetivos = true
    },
  },
}
</script>
