<template>
  <b-overlay
    :show="cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <b-button
      variant="primary"
      class="btn btn-sm float-right mb-1"
      @click="imprimir"
    >
      <b-overlay
        :show="cargandoPdf"
        spinner-variant="primary"
        variant="semi-dark"
      >
        <span class="text-nowrap">Ver PDF</span>
      </b-overlay>
    </b-button>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Resumen Anual"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="legal"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
      margin="5"
    >
      <section slot="pdf-content">
        <div v-for="(group, index) in chunkedItems" :key="index" class="pr-3 pl-3 pt-3">
          <h4 class="mt-1 mb-1 text-center">Resumen Anual {{ nombreCurso }}</h4>
          <table
            class="small mb-5"
            style="border: 1px solid !important;"
          >
            <tr>
              <th v-for="(field, key) in fields" :key="key" class="text-center border pl-50 pr-50"  :style="`width: ${field.width};`">
                <div v-html="formatTitle(field.label)"></div>
              </th>
            </tr>
            <tr v-for="(item, key) in group" :key="key">
              <td class="border text-center"> {{item.numLista}} </td>
              <td class="border"> {{item.primerApellido}} {{item.segundoApellido}} <br> {{item.nombres}}</td>
              <td class="border text-center"> {{item.abreviaturaDiagnostico}} </td>
              <td class="border text-center"> {{item.nombrePrioritario}} </td>
              <template v-for="(nota, key2) in item.promedioAsignaturas">
                <td :key="key2" class="border text-center"> {{nota.nota}} / {{nota.nivel}} </td>
              </template>
              <td class="border text-center"> {{item.promedioFinal === 'NaN' ? '-' : item.promedioFinal }} </td>
              <td class="border text-center"> {{item.nivelFinal}} </td>
            </tr>
          </table>
          <!-- Salto de página entre tablas -->
          <div v-if="index !== chunkedItems.length - 1" class="break-page"></div>
        </div>
      </section>
    </vue-html2pdf>

    <b-table
      id="tablaNotas"
      striped
      small
      noCollapse
      hover
      class="mt-0 rounded"
      style="min-height: 638px !important; margin-top: -14px !important;"
      responsive
      sticky-header
      :current-page="currentPage"
      :items.sync="alumnosNotas.alumnosNotas"
      :fields="fields"
      :bordered="true"
    >
      <!-- Column: Check -->
      <template #cell(colCheck)="data">
        <b-form-checkbox
          :id="`chk-${data.item.id}`"
          v-model="data.item.chkSelected"
        />
      </template>

      <!-- COLUMNAS Indicadores -->
      <template
        #cell()="data"
      >
        <div
          v-if="data.field.key == 'numLista'"
        >
          {{ data.item.numLista }}
        </div>

        <div
          v-else-if="data.field.key == 'alumnos'"
        >
          {{ data.item.nombreAlumno }}
        </div>

        <div
          v-else-if="data.field.key == 'abreviaturaDiagnostico'"
        >
          {{ data.item.abreviaturaDiagnostico }}
        </div>

        <div
          v-else-if="data.field.key == 'nombrePrioritario'"
        >
          {{ data.item.nombrePrioritario }}
        </div>

        <notaAlumnoAsignatura
          v-else-if="data.field.asignatura"
          :item.sync="data.item"
          :field="data.field"
        />

        <conversionNotas
          v-else-if="data.field.key == 'nota'"
          :item.sync="data.item"
          :field="data.field"
        />

        <nivelLogro
          v-else-if="data.field.key == 'nivelLogro'"
          :item.sync="data.item"
          :field="data.field"
        />

      </template>
    </b-table>

  </b-overlay>
</template>

<script>
import {
  BTable, BFormCheckbox, BOverlay, BButton,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'

import spinner from '../../components/spinner.vue'
import notaAlumnoAsignatura from './alumnosAsignatura/notaAlumnoAsignatura.vue'
import conversionNotas from './alumnosAsignatura/conversionNotas.vue'
import nivelLogro from './alumnosAsignatura/nivelLogro.vue'

export default {
  components: {
    BTable,
    BFormCheckbox,
    BOverlay,
    BButton,
    notaAlumnoAsignatura,
    conversionNotas,
    nivelLogro,
    spinner,
    VueHtml2pdf,
  },
  props: {
    alumnos: {
      type: Array,
      required: true,
    },
    notasAlumnos: {
      type: Array,
      required: true,
    },
    idCursoSelected: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      cargando: true,
      spinner: false,
      cargandoPdf: false,
      // chk
      items: [],
      selectedchk: [],
      chkTodo: null,
      checked: null,

      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      color: null,
      textColor: null,
      alumnosNotas: [],
      nombreCurso: '',
      fields: [
        {
          key: 'numLista',
          label: '#',
          sortable: false,
          variant: 'primary',
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '1% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'alumnos',
          label: 'Alumnos',
          sortable: false,
          stickyColumn: true,
          variant: 'light',
          tdClass: 'text-left',
          thStyle: {
            width: '33% !important',
            'text-align': 'left',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'abreviaturaDiagnostico',
          label: 'Diagn. Pie',
          sortable: false,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '5% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombrePrioritario',
          label: '¿Prioritario?',
          sortable: false,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '5% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldNota: {
        key: 'nota',
        label: 'Nota',
        tdClass: 'text-center',
        variant: 'success',
        thStyle: {
          width: '3% !important',
          'text-align': 'center',
          display: 'table-cell',
          'vertical-align': 'middle',
        },
      },
      fieldNivelLogro: {
        key: 'nivelLogro',
        label: 'Nivel de logro',
        tdClass: 'text-center',
        variant: 'success',
        thStyle: {
          width: '3% !important',
          'text-align': 'center',
          display: 'table-cell',
          'vertical-align': 'middle',
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      getPuntajesIndicadoresTransformacion: 'puntajesIndicadores/getPuntajesIndicadoresTransformacion',
      getPeriodoHistorico: 'periodos/getPeriodoHistorico',
      getCursos: 'cursos/getCursos',
    }),
    chunkedItems() {
      const chunkSize = 15; // Cambia esto según tus necesidades
      let result = []
      if (typeof this.alumnosNotas.alumnosNotas !== 'undefined') {
        result = this.alumnosNotas.alumnosNotas.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / chunkSize)
          if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
          }
          resultArray[chunkIndex].push(item)
          return resultArray
        }, [])
      }
      return result
    },
  },
  watch: {
    idCursoSelected(val) {
      this.initialize()
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    ...mapActions({
      fetchPuntajesIndicadoresTransformacion: 'puntajesIndicadores/fetchPuntajesIndicadoresTransformacion',
      fetchAsignaturasCurso: 'asignaturas/fetchAsignaturasCurso',
    }),
    async initialize() {
      await this.fetchPuntajesIndicadoresTransformacion()
      await this.loadAsignaturas(this.idCursoSelected)
      this.setItems(this.alumnos, this.notasAlumnos)
      this.setAlumnosNotas()
    },
    async loadAsignaturas(id_curso) {
      const response = await this.fetchAsignaturasCurso(id_curso)
      await this.setAsignaturas(response)
    },
    setAsignaturas(asignaturas) {
      asignaturas.forEach(asignatura => {
        this.fields.push({
          key: `${asignatura.id}`,
          label: asignatura.nombre,
          tdClass: 'text-center',
          variant: 'secondary',
          asignatura: true,
          thStyle: {
            width: '2% !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        })
      })
      this.fields.push(this.fieldNota)
      this.fields.push(this.fieldNivelLogro)
    },
    setPromedio(nota) {
      let promedio = '-'
      if (nota.length) {
        let cantidadObjetivos = 0
        let sumaNotas = 0
        nota.forEach(n => {
          if (n.nota !== '-') {
            sumaNotas += parseFloat(n.nota)
            cantidadObjetivos += 1
          }
        })
        promedio = sumaNotas / cantidadObjetivos

        const aproximar = true
        if (aproximar) {
          promedio = promedio.toFixed(1)
        } else {
          promedio = promedio.toFixed(1)
        }
        promedio = parseFloat(promedio) === 3.9 ? '4.0' : promedio
      } else {
        promedio = '-'
      }
      return promedio
    },
    setNivelLogro(promedio) {
      let nivel = '-'
      const promedioComparativo = promedio.replace(/\./g, '')
      this.getPuntajesIndicadoresTransformacion.forEach(transformacion => {
        if (parseFloat(promedioComparativo) >= parseFloat(transformacion.rangoDesde)
        && parseFloat(promedioComparativo) <= parseFloat(transformacion.rangoHasta)) {
          nivel = transformacion.nivelLogro
        }
      })
      return nivel
    },
    imprimir() {
      const { nombreGrado, letra } = this.getCursos.find(c => c.id === this.idCursoSelected)
      this.nombreCurso = `${nombreGrado} ${letra}`
      this.$refs.html2Pdf.generatePdf()
    },
    setAlumnosNotas() {
      const alumnosNotas = []
      const asignaturas = []
      this.fields.forEach(field => {
        if (field.asignatura) {
          asignaturas.push({
            idAsignatura: parseInt(field.key, 0),
            nombreAsignatura: field.label,
          })
        }
      })
      this.items.forEach(alumno => {
        const promedioAsignaturas = []
        asignaturas.forEach(asignatura => {
          const {
            idAsignatura,
            nombreAsignatura,
          } = asignatura
          const notasAsignatura = alumno.notasAlumno.filter(na => na.idAsignatura === idAsignatura)
          const nota = this.setPromedio(notasAsignatura)
          const nivel = this.setNivelLogro(nota)
          promedioAsignaturas.push({
            idAsignatura,
            nombreAsignatura,
            nota,
            nivel,
          })
        })
        const promedioFinal = this.setPromedio(promedioAsignaturas)
        const nivelFinal = this.setNivelLogro(promedioFinal)

        alumnosNotas.push({
          idAlumno: alumno.id,
          nombreAlumno: alumno.alumno,
          nombres: alumno.nombres,
          primerApellido: alumno.primerApellido,
          segundoApellido: alumno.segundoApellido,
          numLista: alumno.numLista,
          abreviaturaDiagnostico: alumno.abreviaturaDiagnostico,
          nombreDiagnostico: alumno.nombreDiagnostico,
          nombrePrioritario: alumno.nombrePrioritario,
          promedioAsignaturas,
          promedioFinal,
          nivelFinal,
        })
      })

      const data = {
        idCurso: this.idCursoSelected,
        fields: this.fields,
        asignaturas,
        alumnosNotas,
        tipo: 'read',
      }
      this.alumnosNotas = data
    },


    setItems(alumnos, notas) {
      this.items = []
      alumnos.forEach(alumno => {
        const nombre = `${alumno.primerApellido} ${alumno.segundoApellido} ${alumno.nombres}`
        const notasAlumno = notas.filter(p => p.idAlumno === alumno.id)
        this.items.push({
          nombres: alumno.nombres,
          primerApellido: alumno.primerApellido,
          segundoApellido: alumno.segundoApellido,
          idCurso: alumno.idCurso,
          idAsignatura: alumno.id,
          id: alumno.id,
          numLista: alumno.numLista,
          rut: alumno.rut,
          alumno: nombre,
          nombreDiagnostico: alumno.nombreDiagnostico,
          abreviaturaDiagnostico: alumno.abreviaturaDiagnostico,
          nombrePrioritario: alumno.nombrePrioritario,
          notasAlumno,
        })
      })
      this.cargando = false
    },

    // Checkbox select item Table
    chkAll() {
      this.items.forEach(item => {
        const cliente = this.items.find(i => i.id === item.id)
        cliente.chkSelected = this.chkTodo
      })
    },
    chkCount() {
      let chkCount = 0
      this.items.forEach(item => {
        chkCount = item.chkSelected
          ? chkCount + 1
          : chkCount
      })
      return chkCount === 0
    },

    onProgress(event) {
      if (event === 70) {
        this.cargandoPdf = true
      } else if (event === 100) {
        this.cargandoPdf = false
      }
    },

    formatTitle(label) {
      return label.replace(/\s/g, '<br>')
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of
      // buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
  .break-page {
    page-break-before: always;
  }
  th {
    word-wrap: break-word;
    white-space: normal;
    padding: 5px;
    text-align: center;
    vertical-align: middle;
  }
  td {
    padding: 5px;
  }
</style>
